import React, { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { navigate } from "gatsby"
import Modal from "react-bootstrap/Modal"

const ItemModal = ({ skuModalShow, setSkuModalShow, from, parentId }) => {
  const [itemName, setName] = useState("")
  const [loading, setLoading] = useState(false)

  const changeName = e => {
    setName(e.target.value)
  }

  const addNewItem = async () => {
    if (itemName === "") {
      console.log("Item name cannot be empty")
      return
    }

    if (loading) {
      return
    }

    setLoading(true)

    const postDataObj = {
      itemName: itemName,
      permission: "public",
      from,
    }

    if (parentId) {
      postDataObj.parentID = parentId
    }

    try {
      const response = await AxiosInstance.post("/drive/item/", postDataObj)
      if (response.status === 201) {
        window.location.href = `/drive/item/${response.data.item.fileUrl}`
      } else {
        console.log(`Unexpected response status: ${response.status}`)
      }
    } catch (error) {
      console.error("Error when creating SKU", error)
    }
  }

  const handleClose = () => {
    setSkuModalShow(false)
  }

  return (
    <Modal show={skuModalShow} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="newItemModalTitle">
          <i className="bi bi-cart-fill" /> SKU / Item You Sell
        </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 row align-items-center">
          <label htmlFor="itemName" className="col-sm-4 col-form-label">
            Item Name
          </label>
          <div className="col-sm-8">
            <input
              name="itemName"
              id="itemName"
              placeholder="Name"
              className="form-control"
              type="text"
              value={itemName}
              onChange={changeName}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={handleClose}>
          <i className="bi bi-x-lg" /> Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={addNewItem}>
          <i className="bi bi-check-lg" /> Save
          {loading && <i className="spinner-border spinner-border-sm" />}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ItemModal
